import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

const Tags = ({ tags, langKey }) => {
    return (
        <div>
            {tags && tags.length ? (
                <div>
                    {tags.map((tag) => (
                        <span key={tag + `tag`}>
                            <Link
                                className="has-underline-from-center has-text-primary has-text-weight-medium"
                                to={`/${
                                    langKey === 'en' ? '' : langKey
                                }/blog-tags/${kebabCase(tag)}/`}
                            >
                                {'#' + tag}
                            </Link>
                        </span>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

Tags.propTypes = {
    tags: PropTypes.array,
    langKey: PropTypes.string,
}

export default Tags
